::-ms-reveal {
  display: none;
}
.sidebar-drawer {
  .MuiListItemIcon-root {
    min-width: 36px !important;
  }
  .MuiTypography-root {
    color: #fffceb !important;
  }
}
.sidebar-link {
  text-decoration: none;
  width: 100%;

  .MuiListItemIcon-root {
    filter: brightness(0) saturate(100%) invert(99%) sepia(50%) saturate(492%)
      hue-rotate(344deg) brightness(110%) contrast(101%);
  }
  .MuiTypography-root {
    color: #fffceb !important;
  }
}

.sidebar-link-active {
  background: #fffceb;
  border-radius: 8px;

  .MuiListItemIcon-root {
    filter: invert(43%) sepia(20%) saturate(360%) hue-rotate(75deg)
      brightness(93%) contrast(86%);
  }

  .MuiTypography-root {
    color: #5e7360 !important;
  }
}
.sidebar-icon {
  filter: brightness(0) saturate(100%) invert(99%) sepia(50%) saturate(492%)
    hue-rotate(344deg) brightness(110%) contrast(101%);
}

.MuiChip-root {
  &.MuiChip-outlinedSuccess {
    background-color: #e1fbf2;
    border: 1px solid #bfded3;
    padding-bottom: 2px;

    .MuiChip-label {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #0f875b;
    }

    &.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.16);

      .MuiChip-label {
        color: #444444;
      }
    }
  }

  &.MuiChip-colorError {
    .MuiChip-label {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #f16149;
    }

    &.MuiChip-outlinedError {
      background-color: #ffe7e3;
    }
  }
}

.members-count {
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-weight: 500;
  align-items: center;
}

.MuiButton-textPrimary {
  height: 40px !important;
  background-color: rgba(21, 96, 212, 0.08) !important;
  border-radius: 8px !important;
  border: 1px solid rgba(21, 96, 212, 0.12) !important;

  &:hover {
    background-color: rgba(21, 96, 212, 0.18) !important;
  }
}

.expanded-icon {
  transform: rotate(90deg);
}
.disabled-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  transform-origin: 0% 50%;
  align-items: center;
  cursor: auto;
  img {
    max-width: 40px;
    max-height: 40px;
    cursor: pointer;
    &.disabled-img {
      filter: brightness(0) saturate(100%) invert(92%) sepia(0%) saturate(128%)
        hue-rotate(153deg) brightness(90%) contrast(98%);
    }
  }
}
.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  transform-origin: 0% 50%;
  align-items: center;
  width: max-content;
  margin-left: auto;
  cursor: auto;
  img {
    max-width: 20px;
    max-height: 20px;
    cursor: pointer;

    &:hover {
      background-color: #e6e6e6;
      border-radius: 50%;
      outline: 5px solid #e6e6e6;
    }

    &.disabled-img {
      filter: invert(85%) sepia(0%) saturate(521%) hue-rotate(137deg)
        brightness(96%) contrast(97%);

      &:hover {
        background-color: transparent;
        border-radius: 50%;
        outline: none;
      }
    }
  }
}

.hover-icon-link {
  align-items: center;
  padding: 4px !important;
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    background-color: #e6e6e6;
  }
}
.copy-button {
  &:hover {
    background-color: #e6e6e6;
    border-radius: 50%;
    outline: 5px solid #e6e6e6;
    cursor: pointer;
  }
  &:active {
    background-color: #cecccc;
    outline: 5px solid #cecccc;
  }
}

.img-disabled {
  opacity: 0.3;
}

.custom-checkbox-root {
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
}

.MuiTablePagination-actions {
  &:not(.Mui-disabled) {
    .MuiSvgIcon-root {
      &:hover {
        background-color: #e6e6e6;
        border-radius: 50%;
      }
    }
  }
}

//Loader
.custom-loader-wrapper {
  display: flex;
  position: relative;
  justify-content: center;

  .custom-loader {
    width: 48px;
    height: 48px;
    border: 5px solid #e6e6e6;
    border-bottom-color: #0157de;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.uber-loader {
  .custom-loader {
    width: 24px;
    height: 24px;
    border: 3px solid #e6e6e6;
    border-bottom-color: #c07302;
  }
}
.duplicate-loader {
  .custom-loader {
    width: 24px;
    height: 24px;
    border: 3px solid #e6e6e6;
    border-bottom-color: #463cd2;
  }
}

//toastify
.toastify {
  border-radius: 8px !important;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  min-width: 350px;
  margin-left: auto;
  padding-right: 34px;
  display: flex !important;
  align-items: center;

  .toastify-avatar {
    filter: invert(100%) sepia(0%) saturate(580%) hue-rotate(186deg)
      brightness(106%) contrast(101%);
    margin: 0px 8px 0 0;
    height: 22px;
    width: 22px;
  }

  .toast-close {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
.table-hover-text {
  :hover {
    background-color: #e6e6e6;
  }
}
.text-link {
  width: max-content;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    background-color: rgba(10, 105, 247, 0.08);
    border-radius: 30px;
  }
}
.table-taxt-and-icon-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-left: 5px;
  width: auto;
  cursor: pointer;

  .link-text {
    // font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-decoration: underline;
  }

  .link-icon-wrap {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    border-radius: 50%;

    img {
      max-height: 16px;
      max-width: 16px;
    }
  }

  &:hover {
    background-color: rgba(21, 96, 212, 0.08);
    border-radius: 32px;

    .link-text {
      color: #1560d4;
    }

    .link-icon-wrap {
      background-color: transparent;

      img {
        filter: invert(21%) sepia(79%) saturate(4776%) hue-rotate(212deg)
          brightness(94%) contrast(83%);
      }
    }
  }

  &.cursor {
    cursor: pointer;
  }
}

.table-taxt-verified {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.table-taxt-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 2px 7px 5px;
  width: max-content;
  cursor: pointer;

  .link-text {
    // font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-decoration: underline;
  }

  &:hover {
    background-color: rgba(21, 96, 212, 0.08);
    border-radius: 32px;

    .link-text {
      color: #1560d4;
    }
  }

  &.cursor {
    cursor: pointer;
  }
}

.no-underline {
  text-decoration: none;
}

.table-paper-container {
  width: 100%;
  margin-bottom: 16px;
  border-radius: 16px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  overflow: auto;
}

.avater-img {
  max-width: 19px;
  max-height: 19px;
}

.details-avater-img {
  max-width: 38px;
  max-height: 38px;
}

//quill editor

.quill {
  .ql-toolbar {
    border: 1px solid #cccccc;
    border-radius: 8px 8px 0px 0px;
  }

  .ql-container {
    border-radius: 0px 0px 8px 8px;
    min-height: 175px;
    .ql-editor {
      min-height: 175px;
    }
  }
}

//sweatAlert
.swal2-container {
  z-index: 1160 !important;

  .swal2-popup {
    max-width: 326px;
    border-radius: 8px;

    .swal2-actions {
      margin-top: 0;
      padding: 0;

      .swal2-confirm {
        background: #0b57d0 !important;
        border-radius: 8px;
        font-weight: normal;
        text-transform: uppercase;
        // font-family: $title-font;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff !important;

        &:focus {
          box-shadow: 0 0 0 3px rgb(11 87 208 / 50%);
        }
      }

      .swal2-cancel {
        margin-right: 12px;
        cursor: pointer;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        border: 1px solid #cccccc;

        padding: 0 16px;
        display: flex;
        align-items: center;
        color: #808080;
        height: 36px;
        background-color: #fff;
        border-radius: 8px;
        transition: all 0.3s ease;

        &:hover {
          opacity: 0.8;
          color: #666666f4;
        }
      }
    }

    .swal2-content,
    .swal2-header {
      padding: 0;
    }

    .swal2-title {
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 24px !important;
      color: #000000;
    }

    .swal2-html-container {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      // color: $gray-40;
      margin-bottom: 10px;
    }
  }
}

.week-day-picker {
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 8px;
  width: 100%;

  .picker {
    font-weight: 400;
    line-height: 19px;
    color: #636363;
    width: calc(100% - 58px);
    height: 42px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    &:not(:last-child) {
      border-right: 1px solid #cccccc;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      color: #ffffff;
      background-color: #1560d4;

      &:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      }

      &:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
  }
}

//React date range picker

.calendarWrap {
  display: inline-block;
  position: relative;
  min-width: 250px;

  &.dashBoard-calender {
    .MuiStack-root {
      background-color: #fff;
      border: 1px solid #b5bfce;

      p {
        color: #171717;
      }

      svg {
        filter: brightness(0) saturate(100%) invert(0%) sepia(3%) saturate(534%)
          hue-rotate(317deg) brightness(109%) contrast(82%);
      }
    }
  }
}

.tags-input {
  .MuiAutocomplete-inputRoot {
    height: unset;
    padding: 4px !important;
  }
}

.hover-btn {
  &:hover {
    .btn {
      display: inline-block;
    }
  }
}

.react-select__menu-list {
  max-height: 300px !important;
}

.AsyncPaginate-dropdown {
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  .css-12a83d4-MultiValueRemove:hover {
    color: black;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.external-link {
  display: flex;
  text-decoration: none;
  gap: 7px;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  img {
    filter: invert(32%) sepia(25%) saturate(4815%) hue-rotate(197deg)
      brightness(97%) contrast(93%);
  }
  color: #1560d4;
}

.report-table-Wraper::-webkit-scrollbar {
  height: 12px;
}

.report-table-Wraper::-webkit-scrollbar-track {
  background: #f7f7f7;
}

.report-table-Wraper::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 0;
}
.report-table-Wraper {
  .ExcelTable2007 {
    border-collapse: collapse;
    tr:nth-child(2) {
      font-weight: 600 !important;
      font-size: 13px !important;
      line-height: 16px !important;
    }
    td,
    th {
      border: 1px solid #cccccc;
      padding: 6.5px 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }

    // tr:hover {
    //   background-color: #ddd;
    // }
    th {
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: left;
      background-color: #e6e6e6;
      color: #000000;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
    }
  }
}

// mui table pagination spacer
.MuiTablePagination-spacer {
  display: none;
}

.LinkBtn {
  text-decoration: none !important;
  padding: 4px 8px !important;
  border-radius: 50px !important;
  &:hover {
    background-color: #e6e6e6;
  }
}
.doller-sign {
  margin-right: -10px !important;
  .MuiTypography-root {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
}

.react-select__menu-portal {
  z-index: 1101 !important;
  .react-select__menu-list {
    div {
      height: 36px !important;
      cursor: pointer;
    }
  }
}

.react-select__menu {
  z-index: 1101 !important;
  .react-select__menu-list {
    div {
      cursor: pointer;
      &:hover {
        background-color: #edf3fc;
      }
    }
  }
}

.drag-handle {
  display: none !important;
}

.category-parent {
  &:hover {
    .drag-handle {
      display: block !important;
    }
  }
}

.chat-container {
  height: 88vh;
  width: 98%;
}
