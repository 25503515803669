$primary-color: #007bff;
$secondary-color: #f1f1f1;
$user-color: #d1e7fd;
$border-radius: 10px;
$shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

.chat-container {
  margin: 12px;
  display: flex;
  height: 80vh;
  background: white;
  box-shadow: $shadow;
  border-radius: $border-radius;
  overflow: hidden;
}

.chat-history {
  width: 30%;
  background: #f4f4f4;
  padding: 15px;
  overflow-y: auto;
  border-right: 1px solid #ddd;

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 10px;
      background: white;
      border-radius: $border-radius;
      margin-bottom: 8px;
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background: $secondary-color;
      }
    }
  }
}

.chat {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.messages {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: white;

  .message {
    padding: 30px;
    border-radius: $border-radius;
    max-width: 80%;
    word-wrap: break-word;
    box-shadow: $shadow;

    &.bot {
      background: $secondary-color;
      align-self: flex-start;
    }

    &.user {
      background: $user-color;
      align-self: flex-end;
    }
  }
}

.input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background: white;

  input {
    flex: 1;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: $border-radius;
    outline: none;
    font-size: 14px;
  }

  button {
    padding: 12px;
    margin-left: 10px;
    border: none;
    background: $primary-color;
    color: white;
    border-radius: $border-radius;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background: darken($primary-color, 10%);
    }
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .chat-container {
    flex-direction: column;
    width: 90%;
    height: 90vh;
  }

  .chat-history {
    width: 100%;
    height: 30%;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .chat {
    width: 100%;
    height: 70%;
  }
}
